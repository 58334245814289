import * as React from "react"
import { FC, useRef, useState } from "react"
import styled from "styled-components"
import { SwiperSlide } from "swiper/react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import { gillFont, minionFont, tradeGothic } from "../../constants/fonts"
import { bigDesktop, desktop, largeDesktop, smallMobile, tablet } from "../../constants/screenSizes"
import { backgroundGray, grey, ocher, skyBlue, white } from "../../constants/colors"
import Carousel from "../../components/carousel/Carousel"
import GiftBoxesAccordion from "./GiftBoxesAccordion"
import Stars from "../../../images/stars.png"
import Verified from "../../../images/Verified.png"
import ImpactMeasurement from "../../../svg/impact-measurement.inline.svg"
import OurExperience from "../../../svg/our-experience.inline.svg"
import PlasticWaste from "../../../svg/plastic-waste.inline.svg"
import QualityMeets from "../../../svg/quality-meets.inline.svg"
import RecycledMaterials from "../../../svg/recycled-materials.inline.svg"
import VoidFill from "../../../svg/void-fill.inline.svg"
import Wine from "../../../images/wine.png"
import GImpactBanner from "../../../gatsby/components/GImpactBanner"
import GImage from "../../../gatsby/GImage"
import ProductTaxonomyDetailsCarousel from "../../components/carousel/ProductTaxonomyDetailsCarousel"
import GLink from "../../../gatsby/GLink"
import ThreeSellingPoints from "./ThreeSellingPoints"
import { PrimaryButton, SquareImage } from "components"
import ProductFeatureCollectionCarousel from "../../components/carousel/ProductFeatureCollectionCarousel"
import EnquiryForm from "../../components/landing-page/new/components/lead-capture/enquiry-form/EnquiryForm"
import { ImageType, ProductTaxonomyType } from "model"

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h2<{ margin: string; mobileFontSize: string; width: string }>`
  width: ${({ width }) => `${width || "100%"}`};
  margin: ${({ margin }) => `${margin || "0 auto"}`};
  font-weight: ${({ fontWeight }) => `${fontWeight || "400"}`};
  text-align: center;
  font-family: ${minionFont};
  font-size: 3.3rem;
  line-height: 1;
  text-transform: unset;
  max-width: 1100px;
  @media (max-width: ${tablet}px) {
    font-size: ${({ mobileFontSize }) => `${mobileFontSize || "2.5rem"}`};
    text-align: center;
    padding: 0 10px;
  }
`

const CategoriesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 45px;
  @media (max-width: ${largeDesktop}px) {
    justify-content: space-evenly;
  }
`

const CategoryLink = styled.a`
  font-family: ${gillFont};
  font-size: 1.3rem;
  text-decoration: underline;
  margin-right: 10px;
  :last-child {
    margin: 0;
  }
  @media (max-width: ${tablet}px) {
    font-size: 22px;
  }
`

const RatingContainer = styled.div<{ margin: string }>`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: center;
  font-family: ${tradeGothic};
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1;
  letter-spacing: 1.5px;
  @media (max-width: ${bigDesktop}px) {
    margin-top: 30px;
    font-size: 18px;
  }
  @media (max-width: ${smallMobile}px) {
    flex-direction: column;
    align-items: center;
  }
`

const RatingImg = styled.img`
  width: 125px;
  padding: 5px 20px 0 0;
  @media (max-width: ${bigDesktop}px) {
    padding: 3px 15px 0 0;
  }
  @media (max-width: ${smallMobile}px) {
    padding: 0 0 10px 0;
  }
`

const HeroGrid = styled.div`
  margin: 0 auto;
  padding: 60px 40px 65px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-template-rows: auto auto;
  row-gap: 28px;
  @media (max-width: ${bigDesktop}px) {
    row-gap: 20px;
  }
  @media (max-width: ${tablet}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    padding: 40px 10px;
  }
`

const HeroItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${bigDesktop}px) {
    align-items: center;
  }
`

const HeroItemImage = styled.div`
  object-fit: cover;
  display: flex;
  align-items: center;
  grid-row: 1/3;
  grid-column: 2/3;
  @media (max-width: ${tablet}px) {
    grid-row: unset;
    grid-column: unset;
    margin-bottom: 40px;
  }
`

const MainTitle = styled.h2`
  font-family: ${minionFont};
  font-size: 4.9rem;
  line-height: 0.95;
  letter-spacing: 2.5px;
  text-transform: none;
  margin: 0 0 20px 0;
  @media (max-width: ${largeDesktop}px) {
    font-size: 4rem;
  }
  @media (max-width: ${bigDesktop}px) {
    font-size: 3rem;
    text-align: center;
  }
`

const MainTitleHighlighted = styled.span`
  font-family: ${minionFont};
  color: ${ocher};
  font-weight: bold;
  font-size: 4.9rem;
  line-height: 0.95;
  letter-spacing: 2.5px;
  text-transform: none;
`

const Description = styled.p<{ fontSize: string; margin: string; textAlign: string }>`
  font-family: ${gillFont};
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  margin: ${({ margin }) => margin || 0};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
  line-height: ${({ lineHeight }) => lineHeight || "2"};
`

const Bold = styled.span`
  font-weight: bold;
`

const MainDescription = styled(Description)`
  @media (max-width: ${bigDesktop}px) {
    text-align: center;
  }
`

const ReviewContainer = styled.div`
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Reviewer = styled.div`
  display: flex;
  align-items: center;
`

const VerifiedIcon = styled.img`
  transform: scale(0.75);
  margin: 0 3px;
`

const Block = styled.div<{ padding: string; width: string; paddingTop: string }>`
  position: relative;
  padding: ${({ padding }) => `${padding || "100px 50px"}`};
  max-width: ${({ width }) => `${width || "1200px"}`};
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${bigDesktop}px) {
    justify-content: center;
    padding: ${({ padding }) => `${padding || "80px 0"}`};
    padding-top: ${({ paddingTop }) => `${paddingTop || "120px 0"}`};
    ${({ smallScreenPadding }) => smallScreenPadding && `padding: ${smallScreenPadding}`}
  }
`

const Part = styled.div<{ padding: string; width: string; smallScreenOrder: number }>`
  width: ${({ width }) => width || "46%"};
  ${({ padding }) => padding && `padding: ${padding};`};
  @media (max-width: ${bigDesktop}px) {
    width: 100%;
    padding: 0 27px;
    ${({ smallScreenOrder }) => smallScreenOrder && `order: ${smallScreenOrder}`}
  }
`

const ColorWrapper = styled.div<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  width: 100%;
`

const Section = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`

const VideoWrapper = styled.div`
  position: relative;
  padding: 25px 0 56.25%;
  height: 0;
  margin-bottom: 20px;
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Impacts = styled.div`
  padding: 30px 50px;
  @media (max-width: ${tablet}px) {
    padding: 10px 30px;
  }
`

const ImageStrip = styled.div`
  display: flex;
  @media (max-width: ${tablet}px) {
  }
`

const GiftsForEveryOccasion = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${largeDesktop}px) {
    padding: 50px 10px;
  }
`

const ShowcastingDescriptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
`

const ShowcastingDescription = styled.div`
  text-align: center;
  padding-bottom: 28px;
  font-size: 1.15rem;
  line-height: 1.6;
  letter-spacing: 0.44px;
`

const ShowcastingRating = styled.div`
  padding: 26px 15px 2px;
  font-family: ${tradeGothic};
  font-size: 1.1rem;
  letter-spacing: 2px;
  display: flex;
  align-items: flex-start;
`

const GiftsDescription = styled.p`
  text-align: center;
  max-width: 860px;
  margin: 10px auto 35px;
  font-size: 1.1rem;
  line-height: 2;
  @media (max-width: ${tablet}px) {
    padding: 0 10px;
  }
`

const CarouselItem = styled.div`
  padding: 44px 25px 25px;
  margin-bottom: 70px;
  min-height: 270px;
`

const CustomersCarouselItem = styled(CarouselItem)`
  padding: 40px 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CarouselDescription = styled.div<{ fontSize: string; padding: string; lineHeight: string }>`
  font-size: ${({ fontSize }) => fontSize || "1.05rem"};
  padding: ${({ padding }) => padding || "0 0 20px 0"};
  line-height: ${({ lineHeight }) => lineHeight || 1.8};
`

const CustomersStars = styled.div`
  max-width: 110px;
  margin: 0 auto;
  padding-bottom: 10px;
`

const CustomersAuthor = styled.div`
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 12px;
  font-weight: bold;
`

const CustomersBuyer = styled.div`
  font-size: 1rem;
  line-height: 1;
`

const CarouselWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (max-width: ${bigDesktop}px) {
    max-width: 1200px;
  }
  @media (max-width: ${desktop}px) {
    max-width: 600px;
  }
  @media (max-width: ${tablet}px) {
    max-width: 360px;
  }
`

const StyledButton = styled(PrimaryButton)`
  && {
    width: auto;
    display: inline-block;
  }
`

const ViewAllGiftOptionsButton = styled(StyledButton)`
  && {
    margin-top: 30px;
  }
`

const ImagePart = styled(Part)`
  display: flex;
  align-items: flex-end;
  @media (max-width: ${bigDesktop}px) {
    margin-top: 40px;
  }
`

const CardContainer = styled.div`
  width: 20%;
  @media (max-width: ${largeDesktop}px) {
    width: 25%;
    height: 280px;
    & :last-child {
      display: none;
    }
  }
  @media (max-width: ${tablet}px) {
    width: calc(100% / 3);
    height: 210px;
    & :first-child {
      display: none;
    }
  }
  @media (max-width: ${smallMobile}px) {
    width: 50%;
    & :nth-child(4) {
      display: none;
    }
  }
`

const BestSellingTitle = styled(Title)`
  color: ${white};
  margin-bottom: 70px;
  padding: 0 10px;
`

const categoriesItems = [
  {
    label: "Gifts & Hampers",
    url: "/product-category/hampers-and-gift-boxes",
  },
  {
    label: "Clothes & Accessories",
    url: "/product-category/clothes-accessories",
  },
  {
    label: "Food & Drink",
    url: "/product-category/food-drink",
  },
  {
    label: "Home & Living",
    url: "/product-category/home-living",
  },
  {
    label: "Bath & Beauty",
    url: "/product-category/bath-beauty",
  },
  {
    label: "Corporate",
    url: "/corporate",
  },
]

const whyWeAreDifferentItems = [
  {
    Svg: ImpactMeasurement,
    title: "Impact Measurement",
    description:
      "We measure the tangible impact of your order, including lives impacted, preloved materials saved from landfill or profits donated to charity.",
  },
  {
    Svg: QualityMeets,
    title: "Quality Meets Social Impact",
    description:
      "We work with the best social enterprises in the UK, offering over 1,500 products which have a unique story, each with a positive social and environment impact.",
  },
  {
    Svg: OurExperience,
    title: "Our Experience",
    description:
      "We've delivered tens of thousands of hampers and gift boxes – every item carefully selected by us for its social mission. But we’re also obsessed with taste-testing, so you get only the best quality gifts.",
  },
]

const whyWeCareAboutImpactItems = [
  {
    Svg: RecycledMaterials,
    title: "Recycled Materials",
    description:
      "Wherever possible, we use a minimum of 90% recycled cardboard for our gift boxes. Some of our gift boxes are delivered in wicker or rope paper hampers. The wicker hampers are finished in untreated willow whilst the paper rope is a paper derivative used frequently in packaging.",
  },
  {
    Svg: VoidFill,
    title: "Void fill",
    description:
      "Our packaging is designed to have as little impact as possible. You can throw the shredded cardboard fill in the recycling. Plus, what looks like packing peanuts are actually made from potato starch, so can go straight into your compost bin. Alternatively, you can dissolve them in water.",
  },
  {
    Svg: PlasticWaste,
    title: "Plastic Waste Minimisation",
    description:
      "We always aim to minimise plastic waste throughout our packaging and products. We work with brands who see this as a priority too. If you spot anything which you think has gone overboard on packaging (plastic or otherwise) please do let us know and we would be happy to look into it.",
  },
]

const whatCustomersSayItems = [
  {
    description:
      "Brilliant company! Incredibly friendly, helpful staff and fantastic products! All of the hampers we received were great and supporting good causes in eco-friendly packaging. I would definitely recommend Social Supermarket and would use them again.",
    author: "Alexander",
  },
  {
    description:
      "I've only ever received really high quality items from social supermarket. Also they have a personal service for queries and the goods always arrive quickly and never disappoint.",
    author: "Sue",
  },
  {
    description:
      "Great selection of ethical gifts. My go to place for all kinds of occasions. The website is easy to use and when I realised I'd entered the wrong delivery address, customer service were very quick to respond and fix it for me!",
    author: "Ella",
  },
  {
    description:
      "Excellent quality products, very helpful and proactive customer service. I'd recommend Social Supermarket to anyone looking for ethical gifts",
    author: "Hannah",
  },
  {
    description:
      "I love Social Supermarket. They are my go-to for gifts these days at they have such a great range of products, it's easy and quick to order, plus their packaging is also sustainable.",
    author: "Jessica",
  },
  {
    description:
      "So good to know that there really is a place you can source all manner of stuff which has been selected solely on the basis of quality, provenance and traceability, both environmental and in terms of the human labour used to produce it.",
    author: "Guy",
  },
]

const ShowcastingDescriptionsItems = [
  "We have curated hampers to surprise friends, family and colleagues with food and drink treats that delight.",
  "These collections of sumptuous and award-winning treats – all from social enterprise brands with impact at their core – ensure that your gift will do that little bit more. Social Supermarket is the leading social enterprise marketplace in the UK.",
]

const Questions = [
  {
    header: "Do you offer dietary options e.g. vegan, non alcoholic?",
    message:
      "Yes, absolutely. We are always thinking of ways to make our range as inclusive as possible. We signpost where items or gift boxes have dietary specifications in each product pages.",
  },
  {
    header: "Can I include a gift note?",
    message:
      "Yes, simply add your gift note when you checkout. Note, we have a character limit of 240 characters for this.",
  },
  {
    header: "How long does does delivery take?",
    message:
      "Delivery on our hamper range can be organised as a next day delivery. You’ll see at the checkout your arrival date for our order. You can also pre-order and select an arrival date in the future if you wish.",
  },
  {
    header: "How do you measure the positive impact from my purchase?",
    message:
      "We work with a range of social enterprise suppliers, each with a unique impact, from employing those furthest from the workplace to upcycling material that would normally go to waste. For each purchase we can quantify the social value created e.g. hours of employment created or kilograms of product saved. We try to be as transparent as possible when it comes to impact measurement and also be upfront about any assumptions included.",
  },
  {
    header: "How do you ensure your packaging is eco-friendly?",
    message:
      "In the spirit of all that we stand for at Social Supermarket, we try to be as green as possible when it comes to our packaging. <br/><br/>" +
      "Our boxes are made from majority recycled materials and are recyclable. We also encourage people to reuse the boxes and give them a second life. We source our baskets from suppliers who prioritise sustainable farming methods, including using fast-growing regenerating crops without chemical intervention.<br/><br/>" +
      "We use vegetable inks for our printing requirements, which is more eco-friendly than alternative and traditional inks. <br/><br/>" +
      "In packaging your order, we cushion contents using a fully biodegradable fill. So whatever you’ve ordered, it will arrive in good condition while minimising environmental impact. Win, win. You can throw the shredded cardboard fill in the recycling. Plus, what looks like packing peanuts are actually made from potato starch, so can go straight into your compost bin. Alternatively, you can dissolve them in water.<br/><br/>" +
      "We always aim to minimise plastic waste throughout our packaging and products. We work with brands who see this as a priority too.",
  },
]

const BREAK_POINTS = {
  [smallMobile]: {
    slidesPerView: 1,
    slidesPerGroup: 1,
  },
  [tablet]: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  [desktop]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    allowTouchMove: false,
    pagination: false,
  },
}

interface ProductImageType {
  image: ImageType
  link: string
}

interface Props {
  taxonomies: ProductTaxonomyType[]
  headerImage: ImageType
  productStrip1: ProductImageType[]
  productStrip2: ProductImageType[]
}

interface ExplainsBlockItemType {
  Svg: string
  description: string
  title: string
}

const GiftBoxesLandingPage: FC<Props> = ({
  taxonomies,
  headerImage,
  productStrip1,
  productStrip2,
}): JSX.Element => {
  const [videoUrl, setVideoUrl] = useState<string>(
    "https://www.youtube.com/embed/TQ8KZL-7hGo?autoplay=0"
  )
  const videoContainerRef = useRef<HTMLDivElement>(null)

  return (
    <Layout fullWidth>
      <SEO title="Social enterprise gift boxes" description="Social enterprise gift boxes" />

      <ColorWrapper backgroundColor={backgroundGray}>
        <HeroGrid>
          <HeroItem>
            <MainTitle>
              Give Environmentally Friendly <MainTitleHighlighted>Gift Boxes</MainTitleHighlighted>
            </MainTitle>
            <MainDescription>
              We believe in a world where every business can be a force for good. By connecting you
              with social enterprises, you can buy from amazing brands that have a positive impact
              in the world.
            </MainDescription>
          </HeroItem>
          <HeroItemImage>
            <GImage image={headerImage} style={{ width: "100%" }} />
          </HeroItemImage>
          <HeroItem>
            <StyledButton to="/product-category/hampers-and-gift-boxes">
              Get my gift Box
            </StyledButton>
            <ReviewContainer>
              <RatingImg src={Stars} alt="stars rating" />
              <Description fontSize="1.4rem" margin="10px 0 5px" lineHeight="1.4">
                “<Bold>Excellent quality products</Bold>, very helpful and proactive customer
                service. I'd recommend Social Supermarket to anyone looking for ethical gifts”
              </Description>
              <Reviewer>
                <Description fontSize="0.9rem" lineHeight="1.4">
                  Hannah
                </Description>
                <VerifiedIcon src={Verified} alt="verified icon" />
                <Description fontSize="0.9rem" lineHeight="1.2">
                  Verified Buyer
                </Description>
              </Reviewer>
            </ReviewContainer>
          </HeroItem>
        </HeroGrid>
      </ColorWrapper>

      <ColorWrapper backgroundColor={skyBlue}>
        <Section>
          <Impacts>
            <GImpactBanner
              iconSlugs={[
                "eco-conscious",
                "economic-empowerment",
                "handmade",
                "charitable-contributions",
                "repurposed",
              ]}
            />
          </Impacts>
        </Section>
      </ColorWrapper>

      <Section>
        <ImageStrip>
          {productStrip1.map(item => (
            <CardContainer key={item.link}>
              <GLink to={item.link}>
                <SquareImage image={item.image} objectFit={"cover"} />
              </GLink>
            </CardContainer>
          ))}
        </ImageStrip>
      </Section>

      <ThreeSellingPoints
        items={whyWeAreDifferentItems}
        backgroundColor={white}
        title="Why We're Different"
      />
      <ColorWrapper backgroundColor={grey}>
        <Block padding="70px 50px 25px" width="1240px">
          <Title width="800px" margin="0 auto 40px">
            Showcasing The Very Best In Social Enterprise Food & Drinks.
          </Title>
          <Part width="51%">
            <VideoWrapper ref={videoContainerRef}>
              <StyledIframe src={videoUrl} allow="autoplay; encrypted-media" frameBorder="0" />
            </VideoWrapper>
          </Part>
          <Part width="46%">
            <ShowcastingDescriptions>
              {ShowcastingDescriptionsItems.map(item => (
                <ShowcastingDescription key={item}>{item}</ShowcastingDescription>
              ))}
              <StyledButton to="/product-category/hampers-and-gift-boxes">
                GET MY GIFT BOX
              </StyledButton>
              <ShowcastingRating>
                <RatingImg src={Stars} alt="stars rating" />
                AN AVERAGE OF 4.76 RATING BASED ON 76 REVIEWS
              </ShowcastingRating>
            </ShowcastingDescriptions>
          </Part>
        </Block>
      </ColorWrapper>

      <Section>
        <GiftsForEveryOccasion>
          <Title>Gifts For Every Occasion</Title>
          <GiftsDescription>
            Whatever your reason for wanting to send a gift box, we’re here to help – whether to
            recognise a friend's achievement, to thank a loved-one or simply to send someone a
            gesture of support and strength during these challenging times.
          </GiftsDescription>
          <ProductTaxonomyDetailsCarousel taxonomies={taxonomies} />
          <ViewAllGiftOptionsButton to="/product-category/hampers-and-gift-boxes">
            VIEW ALL GIFT OPTIONS
          </ViewAllGiftOptionsButton>
        </GiftsForEveryOccasion>
      </Section>

      <ThreeSellingPoints
        items={whyWeCareAboutImpactItems}
        backgroundColor={grey}
        title="We Care About Impact & Sustainability"
      />
      <ColorWrapper backgroundColor={skyBlue}>
        <Block padding="65px 0 100px" width="1200px">
          <BestSellingTitle>Our Best Selling Gift Hampers</BestSellingTitle>
          <ProductFeatureCollectionCarousel collectionName="Hamper Landing Page – Best Sellers" />
        </Block>
      </ColorWrapper>

      <Block padding="70px 50px 54px">
        <Title margin="0 auto 42px">Here's What Our Customers Have To Say...</Title>
        <CarouselWrapper>
          <Carousel
            loop
            showArrows
            swiperProps={{
              slidesPerView: 3,
              slidesPerGroup: 3,
              breakpoints: BREAK_POINTS,
            }}
          >
            {whatCustomersSayItems.map(product => (
              <SwiperSlide key={product.description}>
                <ColorWrapper backgroundColor={grey}>
                  <CustomersCarouselItem>
                    <CustomersStars>
                      <img src={Stars} alt="stars rating" />
                    </CustomersStars>
                    <div>
                      <CarouselDescription fontSize="1rem" lineHeight="1.6" padding="0 0 24px 0">
                        {product.description}
                      </CarouselDescription>
                    </div>
                    <div>
                      <CustomersAuthor>{product.author}</CustomersAuthor>
                      <CustomersBuyer>Verified buyer</CustomersBuyer>
                    </div>
                  </CustomersCarouselItem>
                </ColorWrapper>
              </SwiperSlide>
            ))}
          </Carousel>
        </CarouselWrapper>
      </Block>

      <Section>
        <ImageStrip>
          {productStrip2.map(item => (
            <CardContainer key={item.link}>
              <GLink to={item.link}>
                <SquareImage image={item.image} objectFit={"cover"} />
              </GLink>
            </CardContainer>
          ))}
        </ImageStrip>
      </Section>

      <ColorWrapper backgroundColor={backgroundGray}>
        <Block width="760px" padding="68px 50px 50px" smallScreenPadding="75px 50px 80px">
          <QuestionContainer>
            <Title fontWeight="bold">Questions?</Title>
            <Title margin="8px 0 0 0">We’ve Got You Covered</Title>
            <GiftBoxesAccordion items={Questions} />
            <StyledButton to="/product-category/hampers-and-gift-boxes">
              Get My Gift Box
            </StyledButton>
            <RatingContainer>
              <RatingImg src={Stars} alt="stars rating" />
              <p>An average of 4.76 Rating based on 76 reviews</p>
            </RatingContainer>
          </QuestionContainer>
        </Block>
      </ColorWrapper>

      <Block padding="70px 50px 0" width="1300px">
        <Part width="51%" padding="0 0 0 25px">
          <Title margin="0 0 10px 0">Want To Place a Large Order?</Title>
          <Description fontSize="1.3rem" textAlign="center" lineHeight="1.5">
            If you are ordering for a company or ordering large quantities, please leave your
            details and we will contact you
          </Description>
          <EnquiryForm source={"Inbound Enquiry - Gifting"} name={"GiftingEnquiryForm"} />
        </Part>
        <ImagePart width="47%">
          <img src={Wine} alt="Wine picture" />
        </ImagePart>
      </Block>

      <ColorWrapper backgroundColor={backgroundGray}>
        <Block padding="70px 50px" width="1050px">
          <Title>Shop By Category</Title>
          <CategoriesContainer>
            {categoriesItems.map(({ label, url }) => (
              <CategoryLink href={url} key={url}>
                {label}
              </CategoryLink>
            ))}
          </CategoriesContainer>
        </Block>
      </ColorWrapper>
    </Layout>
  )
}

export default GiftBoxesLandingPage
