import { VariantType } from "model"
import { transformImage } from "./imageTransformer"

export const multipleFromGatsby = (gatsbyVariants: any[]) => {
  return gatsbyVariants ? gatsbyVariants.map(mapVariant) : undefined
}

export const mapVariant = (gatsbyVariant: any): VariantType => {
  return gatsbyVariant
    ? {
        name: gatsbyVariant.name,
        price: gatsbyVariant.price ? gatsbyVariant.price : 0,
        variantId: gatsbyVariant.variationId,
        sku: gatsbyVariant.sku,
        stockStatus: gatsbyVariant.stockStatus,
        attributes: gatsbyVariant.attributes?.nodes.map(({ name, value }) => ({
          name,
          value,
        })),
        image: transformImage(gatsbyVariant.image, gatsbyVariant.name),
        remoteIds: gatsbyVariant.remoteIds,
        description: gatsbyVariant.description,
      }
    : undefined
}
